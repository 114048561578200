import { Provider } from "react-redux"
import ModalProvider from "./contexts/ModalContext/ModalProvider"
import { store } from "./store"
import { RolloutFlagProvider } from "./store/rolloutFlag"
import { KetchContextProvider } from "./contexts/KetchContext"
import { ClickIdCookieWriter } from "./lib/QueryToCookieConverter"

const ProductionProvider = ({ element }) => {
  return (
    <Provider store={store}>
      <RolloutFlagProvider>
        <KetchContextProvider>
          <ClickIdCookieWriter
            clickIds={["ttclid", "fbclid", "gclid", "d", "promo"]}
          />
          <ModalProvider>{element}</ModalProvider>
        </KetchContextProvider>
      </RolloutFlagProvider>
    </Provider>
  )
}

export default ProductionProvider
